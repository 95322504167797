<template>
    <div>
      <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar flat>
        <v-icon left>mdi-bullhorn-outline</v-icon>
        <v-icon left>mdi-whatsapp</v-icon>
        
        <v-toolbar-title>CAMPAÑAS DE WHATSAPP</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirListaContactoDialog(null)" color="blue" small dark
          ><v-icon left>mdi-plus</v-icon> Crear</v-btn
        >
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn :to="`crm_contactos`" color="blue" small dark 
          ><v-icon left>mdi-account-multiple-plus</v-icon>Buscar contactos</v-btn
        >

        <!-- <v-btn class="mx-4" color="blue" small dark
          ><v-icon left>mdi-upload</v-icon>Importar contactos</v-btn
        > -->
        
      </v-toolbar>
  
      <v-data-table
        :headers="headersCampania"
        :items-per-page="itemsPerPage"
        :items="listasContactos"
        class="elevation-1"
        small
        hide-default-footer
      >
        <template v-slot:item.contact_list_id="{ item }">
          <v-btn
            text
            small
            color="green"
            @click="abrirListaContactoDialog(item.contact_list_id)"
            ><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="borrarListaContacto(item.contact_list_id)" text small>
            <v-icon color="red" small>mdi-delete</v-icon>
          </v-btn>

          <v-btn @click="detalleListaContacto(item.contact_list_id)" text small>
            <v-icon color="green" >mdi-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
  
      <!-- dialogo campanias -->
      <v-dialog v-model="dialogCampania" persistent width="700">
        <v-form ref="formCampania">
          <v-card>
            <v-toolbar dark color="blue" height="40" flat>
              <v-icon left>mdi-bullhorn-outline</v-icon>
              Campaña Whatsapp
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogCampania = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  v-model="datosListac.description"
                  label="Descripcion"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-select
                  :rules="requiredRule"
                  :items="tiposLista"
                  item-text="description"
                  item-value="contact_list_type_id"
                  v-model="datosListac.contact_list_type_id"
                  label="Tipo de lista"
                  
                ></v-select>
              </v-col> 

              <v-col md="12" class="py-0 my-0">
                <v-select
                  :items="tiposMecanismos"
                  item-text="description"
                  item-value="contact_mech_type_id"                  
                  v-model="datosListac.contact_mech_type_id"
                  label="Tipo de mecanismo"
                  readonly
                  
                ></v-select>
              </v-col>


              <v-col md="12" class="py-0 my-0">
                <v-select
                  :items="dispositivos"
                  item-text="device_number"
                  item-value="device_id"                  
                  v-model="datosListac.device_id"
                  label="Dispositivo"
                  
                ></v-select>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-select
                  :items="plantillas"
                  item-text="nombre"
                  item-value="message_template_id"                  
                  v-model="datosListac.message_template_id"
                  label="Plantilla de mensaje"
                  
                ></v-select>
              </v-col>

              <!-- <v-col md="12" class="py-0 my-0">
                <v-select
                  :items="tiposWa"
                  item-text="description"
                  item-value="tipo_wa_id"
                  v-model="datosListac.wa_tipo_mensaje"
                  label="Tipo de mensaje de Whatsapp"
                  
                ></v-select>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  v-model="datosListac.wa_texto"
                  label="Texto del mensaje"
                  
                ></v-text-field>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-file-input
                  v-model="datosListac.wa_media_url"
                  label="Archivo de contenido"
                  
                ></v-file-input>
              </v-col> -->
  
               
            </v-row>
  
            <v-toolbar>
              <v-btn color="primary" small @click="guardarListaContacto">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn>
              <v-divider vertical class="px-2" inset></v-divider>
              <v-btn text small @click="dialogCampania = false">
                <v-icon>mdi-cancel</v-icon>Cancelar
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- DIALOGO DETALLE DE CAMPAÑA-->
      <v-dialog v-model="dialogContactos" persistent fullscreen scrollable >
        
          <v-card>
            <v-card-tile>
              <v-toolbar dark color="blue" height="40" flat fixed>
              Contactos de la la lista {{ datosListac.contact_list_id }}
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogContactos = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            </v-card-tile>
            
            <v-card-text>
              
              <v-toolbar flat>
                <v-title-toolbar>Total: {{ contactosLista.length  }}</v-title-toolbar>                
                <v-col cols="3">
                  <v-text-field class="mt-5" label="Buscar" v-model="buscar"></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-btn small color="blue" dark @click="borrarSeleccion()" ><v-icon>mdi-delete</v-icon>Borrar</v-btn>
                </v-col>

                <v-col cols="4">
                  <v-file-input v-model="archivo" @change="importarArchivo()" class="pt-5 " visible="false" label="Importar contactos"></v-file-input>
                </v-col>
              </v-toolbar>
            <v-row class="mt-2 mx-1 pt-0 pt-0">
              <v-col md="12" class="py-0 my-0 mx-0 px-0">
                
                <v-data-table
                  v-model="selected"
                  :search="buscar"
                  :items="contactosLista"
                  :headers="headersContactosLista"
                  :items-per-page="itemsPerPage"
                  show-select
                  item-key="party_id"

                  class="elevation-1"
                  small
                  
                >
                <template v-slot:item.opciones="{item}">
                  <v-btn text small color="red" title="Eliminar contacto" @click="borrarContacto(item)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <template v-slot:item.first_name="{item}">
                  {{  item.first_name  }} {{ item.last_name }} {{ item.group_name }}
                </template>
                <!-- <template v-slot:footer>
                <v-pagination
                  class="mt-10"
                  v-model="currentPage"
                  :length="pageCount"
                  @input="handlePageChange"
                  total-visible="10"
                ></v-pagination>
              </template> -->
                </v-data-table>  
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-toolbar class="mt-0 pt-0 pb-0 mb-0 elevation-4" color="#cecece" fixed flex height="40" >
              <!-- <v-btn color="primary" small @click="guardarAnuncio">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn> -->
              <!-- <v-divider vertical class="px-2" inset></v-divider> -->

              <v-btn text small @click="enviarMensajesLista()">
                <v-icon>mdi-run</v-icon>Iniciar
              </v-btn>

              <v-btn text small @click="dialogContactos = false">
                <v-icon>mdi-cancel</v-icon>Cerrar
              </v-btn>
            </v-toolbar>
          </v-card-actions>
          </v-card>
        
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import Vue from 'vue'  
  import axios from 'axios'

  export default {
    name: "CampaniaWha",
    data: () => ({
      show: false,
      buscar: "",
      
      requiredRule: [(v) => !!v || "El campo es requerido"],
      options: {},
      setfilters: [],
      selected: [],
      users: [],
      totalItems: 1,
      currentPage: 1,
      itemsPerPage: 10,
      pageCount: 1,
      overlay: false,
  
      required: [(v) => !!v || "El campo es requerido"],
  
      headersCampania: [
        { text: "ID", align: "start", value: "contact_list_id" },
        { text: "Tipo", align: "start", value: "tipo_lista" },
        { text: "Mecanismo", align: "start", value: "mecanismo" },
        { text: "Descripcion", align: "start", value: "description" },
      ],
      listasContactos: [],
      dialogCampania: false,
      datosListac: {
        contact_list_id: "",
        contact_list_type_id: "",
        contact_mech_type_id: "TELECOM_NUMBER",
        status_id: "",
        description: "",
        estado: "",
        wa_tipo_mensaje: "",
        wa_texto: "",
        wa_media_url: "",
        wa_content_id: "",
        num_contacto_origen: ""
        
      },
      archivo: null,
      tiposLista: [],
      tiposMecanismos: [{contact_mech_type_id:"TELECOM_NUMBER", description : "Teléfono"},
      {contact_mech_type_id:"EMAIL_ADDRESS", description : "Email"}
      ],
      tiposWa: [{tipo_wa_id:"WA_TEXTO", description: "Texto"},{tipo_wa_id:"WA_IMG", description:"Imagen"}],
      crearLista: false,
      dialogContactos: false,

      headersContactosLista: [
        { text: "Opciones", align: "start", value: "opciones" },
        { text: "Nombres", align: "start", value: "first_name" },
        { text: "Telefono", align: "start", value: "num_telefono" },
        { text: "Estado", align: "start", value: "status_id" },
      ],
      contactosLista: [],

      plantillas: [],
      dispositivos: [],

      /*headersAnuncios: [
        { text: "ID", align: "start", value: "mk_anuncio_id" },
        { text: "Nombres", align: "start", value: "descripcion" },
      ],
      dialogAnuncio: false,
      anuncio_nombre: "",*/
    }),
    computed: {
      ...mapState("master", ["loadingBtn", "companies",'url','urlApi','user','token',]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
      ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
      ]),
      ...mapActions("master", ["requestApi", "alertNotification"]),
      ...mapActions("access", []),
  
      cargarListaContactos() {
        //this.setLoadingTable(true);
        this.overlay = true;
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,
            page_count: this.pageCount,
            page_size: this.itemsPerPage,
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.listasContactos = res.data._embedded.lista_contactos;
            
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });
      },
      
      abrirListaContactoDialog(lcId) {
        this.datosListac = {
          contact_mech_type_id: "TELECOM_NUMBER",
          contact_list_id: "",
          description: "",
        };
        this.dialogCampania = true;
        this.crearLista = true;
        if (lcId != null) {
          this.crearLista = false;
          this.cargarDatosListac(lcId);
        }
      },
  
      cargarDatosListac(id) {
        this.setLoadingTable(true);
        this.setUrl("lista-contactos/" + id);
        this.requestApi({
          method: "GET",
          data: {},
        })
          .then((res) => {
            //console.log(res.data.detail);            
            this.datosListac = res.data.detail.lista_contactos;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
      guardarListaContacto() {
        if (!this.$refs.formCampania.validate()) {
          return false;
        }
        //this.setLoadingTable(true);
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "POST",
          data: {
            accion:
              this.crearLista == true ? "crearLista" : "modificarLista",
            datosListac: this.datosListac,
          },
        })
          .then((res) => {
            //console.log(res.data.detail);
  
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
            if (this.crearLista == true) {
              this.dialogCampania = false;
              this.cargarListaContactos()
              //this.cargarDatosCampania(res.data.detail.cm_id);
            } else {
              this.cargarListaContactos()
              this.dialogCampania = false;
            }
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
  

    borrarListaContacto(lcId) {

      Vue.swal({
            html: "Está seguro de eliminar esta campaña ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
        }).then( result => {
            
            if (result.isConfirmed) {

              this.setLoadingTable(true);
              this.setUrl("lista-contactos");
              this.requestApi({
                method: "POST",
                data: {
                  accion: "borrarLista",
                  contact_list_id: lcId,
                },
              })
                .then((res) => {
                  //console.log(res.data.detail);
        
                  this.alertNotification({
                    param: {
                      html: res.data.detail.msg,
                    },
                  });
                  this.cargarListaContactos()
                })
                .then(() => {
                  this.setLoadingTable(false);
                });
            }
          })
      },
       


      cargarDatosListaWhatsap() {
        //this.setLoadingTable(true);
        this.overlay = true;
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "POST",
          data: {
            accion: "datosLista"
          },
        })
          .then((res) => {            
            this.tiposLista = res.data.detail.tipos_lista;
            this.plantillas = res.data.detail.plantillas;
            this.dispositivos = res.data.detail.dispositivos;
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });
      },

      cargarContactosLista(clId) {
        this.overlay = true;
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "POST",
          data: {
            accion: "contactosLista",
            contact_list_id: clId
          },
        })
          .then((res) => {            
            this.contactosLista = res.data.detail;
            this.datosListac.contact_list_id = clId;
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });
      },

      enviarMensajesLista() {
        this.overlay = true;
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "POST",
          data: {
            accion: "enviarMensajesLista",
            contact_list_id: this.datosListac.contact_list_id
          },
        })
          .then((res) => {
            this.contactosLista = res.data.detail;
            this.overlay = false;
            this.setLoadingTable(false);
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          }).catch(error=>{
            this.setLoadingTable(false);
            this.overlay=false
          });
          ;
      },

      detalleListaContacto(clId) {
        this.dialogContactos=true
        this.selected = [];
        this.archivo = null;
        this.cargarContactosLista(clId)
      },

      borrarContacto(item) {
        this.overlay = true;
        this.setUrl("lista-contactos");
        this.requestApi({
          method: "POST",
          data: {
            accion: "borrarContacto",
            contact_list_id: item.contact_list_id,
            party_id: item.party_id
          },
        })
          .then((res) => {            
            //this.contactosLista = res.data.detail;
            this.cargarContactosLista(item.contact_list_id)
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });

      },
  
      handlePageChange(value) {
        this.cargarListaContactos()
      },

      importarArchivo() {
            
            let tamanoTotal = 0;
            for (let i = 0; i < this.archivo.length; i++) {
                tamanoTotal += this.archivo[i].size;
            }

            if (this.archivo.length > 10 && tamanoTotal < 10000000) {
                alert("Va a cargar muchos archivos está seguro ? ");
                return false;
            }

            if (tamanoTotal >= 100000000) {
                alert("Va a cargar archivos por más de 100MB está seguro ? ");
                return false;
            }
            let formData = new FormData();
            formData.append(
                "contact_list_id",
                this.datosListac.contact_list_id
            );
            formData.append("tenantId", this.tenantId);
            formData.append("accion", "importarArchivo");
            formData.append("user", this.user.user_ligin_id);

            
            formData.append("archivos[]", this.archivo);
            this.selected = [];
            this.overlay = true;
            this.setUrl("lista-contactos");
            this.seleccion
            axios.post(this.url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + this.token,
                    },
                })
                .then((res) => {
                    //this.setLoadingBtn(false);
                    //console.log(res);
                    this.overlay = false;
                    
                    this.alertNotification({
                        param: {
                            html: res.data.detail.msg,
                        },
                    });
                    this.archivo = null;
                    this.cargarContactosLista(this.datosListac.contact_list_id)
                })
                .catch((err) => {
                    //console.log(err);
                    this.overlay = false;
                    //this.setLoadingBtn(false);

                    
                })
                .finally((err) => {
                    //console.log(err);
                    this.overlay = false;
                });
        },


      borrarSeleccion() {

        Vue.swal({
            html: "Está seguro de eliminar estos contactos  ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
        }).then( result => {
            
            if (result.isConfirmed) {
              this.overlay = true;
              this.setUrl("lista-contactos");
              this.requestApi({
                method: "POST",
                data: {
                  accion: "borrarSeleccion",
                  contact_list_id: this.datosListac.contact_list_id,
                  seleccion: this.selected
                },
              })
                .then((res) => {            
                  //this.contactosLista = res.data.detail;
                  this.cargarContactosLista(this.datosListac.contact_list_id)
                  this.overlay = false;
                })
                .then(() => {
                  this.setLoadingTable(false);
                  this.overlay = false;
                }).catch(()=>{
                  this.overlay = false;
                });
              }
            });

      },
    },


    mounted() {
      this.cargarListaContactos();
      this.cargarDatosListaWhatsap();
      this.setTitleToolbar("CAMPANIAS DE WHATSAPP");
    },
  };
  </script>
  